.circular-gauge {
	transform: rotate(-90deg);
  }
  
  /* .gauge-bg {
	opacity: 0.2;
  }
   */
  .gauge {
	transition: stroke-dashoffset 0.5s;
	transform-origin: center;
  }
  
  .circular-gauge .gauge-bg {
	opacity: 0.2;
	stroke: lightgray;
  }
  
  .circular-gauge .gauge {
	/* stroke: rgb(0, 162, 255); */
	/* stroke: #468cfd; */
	stroke: #FF5810;
  }