.tab {
	/* background: yellow; */
	overflow-x: hidden;
	/* border: 1px solid red; */
}

.tab-content {
	flex-grow: 1;
	overflow-y: auto;
	overflow-x: hidden;
	padding-top: 90px;
}

/* Mobile Phones (up to 1023px) */
@media (max-width: 1023px) {
	.tab-content {
		padding-bottom: 60px;
	}
}

/* Laptops/Desktops (1024px and above) */
@media (min-width: 1024px) {
	/* .tab-content {
		padding-top: 90px;
	} */
}