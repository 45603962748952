/* General Styles for the MenuBar */

.menuBar {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0 10px;
	width: 75%;
	/* border: 1px solid cyan; */
	background: white;
	box-sizing: border-box;
}

.menuBar .logo {
	flex-basis: 33%;
	/* border: 1px solid red; */
}

.menuBar .logo img {
	width: 120px;
	/* border: 1px solid purple; */
	display: block; /* Add this line */
	padding: 10px 0;
}

.tabSelector {
	/* flex-basis: 33%; */
	flex-grow: 0;
	display: flex;
	justify-content: center;
	/* border: 1px solid green;	 */
	width: 280px;
	background: white;
	transition: bottom 0.3s ease; /* Adding transition for smooth easing */
}

.locationGuide {
	flex-basis: 33%;
	display: flex;
	/* flex-direction: row-reverse; */
	justify-content: flex-end	;
	align-items: center;
	/* border: 1px solid blue; */
	gap: 10px;	
	background: white;
}

.locationSelector {
	font-size: 1.5rem;
	/* font-weight: bold; */
	/* border: 1px solid yellow;	 */
}

.menuBar .guideSelector {
	font-size: 1.5rem;
	/* font-weight: bold; */
	/* border: 1px solid orange;	 */
}

/* Mobile Styles */
@media (max-width: 1023px) {

	/* .menuBar .logo {
		display: none;
	} */

	/* .locationGuide {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		display: flex;
		justify-content: space-between;
		padding: 5px 10px;
		box-sizing: border-box;
	} */

	.tabSelector {
		position: fixed;
		bottom: 0;
		left: 0;
		width: 100%;
		display: flex;
		/* padding: 5px 10px; */
		box-sizing: border-box;
	}

}
