.guide-container {
	position: relative;
	/* border: 1px solid orange; */
}

.guide-selector {
	display: flex;
	flex-direction: row-reverse;
}

.guide-image {
	width: 50px;
	height: 50px;
	margin-left: 5px;
	border-radius: 50%;
	border: 3px solid rgb(82, 82, 82);
	/* border: 3px solid #106DFF; */
}
