.product-strip {
    margin-left: 10px;
    margin-bottom: 40px;
    width: 100%;
    flex: 1 1 auto; /* Flex-grow, flex-shrink, and flex-basis */
    overflow-x: auto; /* Allow horizontal scrolling */
}

.product-strip h1 {
    margin: 0;
    margin-bottom: 10px;
    font-size: 1.5rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.product-list {
    display: flex;
    overflow-x: auto; /* Allow horizontal scrolling */
}

.product-list::-webkit-scrollbar {
    display: none; /* Hide scrollbar for aesthetics */
}


.product-tile-spacer {
	width: 150px;
	flex-shrink: 0; /* Prevent the spacer from shrinking */
	/* border: 1px solid red; */
}