.contactButtons {
	display: flex;
	justify-content: space-around;
	padding: 10px 0;
	/* background: #000; */
	color: #fff;
}

.contactButton {
	background: #333;
	border: none;
	padding: 10px 20px;
	border-radius: 5px;
	display: flex;
	flex-direction: column;
	align-items: center;
	cursor: pointer;
	width: 18%;
	color: #fff;
}

.contactButton img {
	width: 24px;
	height: 24px;
	margin-bottom: 5px;
}

.contactButton:disabled {
	background: #444;
	color: #888;
	cursor: not-allowed;
}

.contactButton:disabled .disabled-icon {
	filter: grayscale(100%);
	opacity: 0.5;
}