.productDetail-footnotes {
	padding: 15px;
	/* background-color: rgba(0, 0, 0, 0.8); */
	/* color: white; */
	font-size: 13px;
}

.productDetail-footnotes h2 {
	margin: 0 0 10px 0;
}

.footnotes-label {
	/* color: white; */
}

.footnotes-field {
	color: gray;
	padding-bottom: 10px;
}

.footnotes-field a {
	color: gray;
	text-decoration: none;
}
