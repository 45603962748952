.mainMedia {
	position: relative;
	width: 100%;
}

.mainMedia .wrapper {
	position: relative;
	width: 100%;
	padding-top: 177.78%; /* 9:16 aspect ratio (9 / 16 * 100) */
	background: black;
	overflow: hidden;
}

.mainMedia .wrapper img {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	/* height: 100%; */
}

