.tab-selector {
	display: flex;
	justify-content: space-around;
	background-color: white;
	padding: 0;
	/* border: 1px solid blue; */
	box-sizing: border-box;
	gap: 10px;
}

.tab-button {
	display: flex;
	align-items: center;
	cursor: pointer;
	/* border: 1px solid yellow; */
}

.tab-image {
	width: 60px;
	height: 60px;
}

.tab-button.selected .tab-image {
	filter: none;
}

.tab-button:not(.selected) .tab-image {
	filter: grayscale(100%);
}

/* Mobile Phones (up to 1023px) */
@media (max-width: 1023px) {
	.tab-selector {
		width: 100%;
		max-width: 500px;
	}
}

/* Laptops/Desktops (1024px and above) */
@media (min-width: 1024px) {
	.tab-selector {
		/* width: 250px; */
	}
}
