.guide-grid {
	position: absolute;
	top: 80px;
	right: 0px;
	background-color: rgba(0, 0, 0, 0.9);
	border: 2px solid gray;
	border-radius: 10px;
	padding: 20px;
	color: white;
	z-index: 1000;
	padding: 0px 20px 20px;
	width: 360px;

	display: flex;
	flex-direction: column;
	align-items: center; 

	font-size: small;
}

.guide-grid h3 {
	margin-bottom: 10px;
	text-align: center;
}

.guide-grid

.guides {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-around;
	width: 100%; 
}

.guide-item {
	width: 30%;
	margin-bottom: 10px;
	text-align: center;
	cursor: pointer;
}

.guide-item img {
	width: 100%;
	height: auto;
	border-radius: 50%;
	border: 3px solid transparent;
}

.guide-item.selected img {
	/* border-color: #007bff; */
	background-color: #FE2400;
}

.explore-button {
	width: 100%;
	padding: 10px;
	/* background-color: #007bff; */
	background-color: #FE2400;
	color: white;
	border: none;
	border-radius: 5px;
	cursor: pointer;
	text-align: center;
	margin-top: 10px;
}

.explore-button:hover {
	/* background-color: #0056b3; */
	background-color: #FE2400;
}
