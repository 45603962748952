.actionButtons {
	display: flex;
	justify-content: space-between	;
}

.actionButtons .hidden {
	display: none;
}

.actionButtons button {
	border: 0;
	background: transparent	;
	width: 40px;
	height: 40px;	
	padding: 0;
}

.actionButtons button img {
	width: 40px;
	height: 40px;	
}

.actionButtons .leftButtons {
	display: flex;
	gap: 10px;
}

.actionButtons .rightButtons { 
	display: flex;
	gap: 10px;
}

.actionButtons .rightButtons .videoButtons {
	display: flex;
	gap: 10px;
}