.product-pane {
    position: fixed;
    top: 0;
    right: 0;
    width: 25%;
    height: 100vh;
    overflow-x: hidden;
    overflow-y: auto;
    background: white;
    z-index: 1000;
}

.product-pane.hidden {
    display: none;
}

.product-pane.visible {
    display: block;
}

/* Mobile Phones (up to 1023px) */
@media (max-width: 1023px) {
    .product-pane {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        z-index: 2;
        background: white;
    }
}
