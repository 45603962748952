.productDetail {
	position: relative;
	flex-grow: 1;
	overflow-y: auto;
	overflow-x: hidden;
	/* background: black; */
	/* border: 1px solid orange; */
	padding-bottom: 100px;
  }

  .productDetail .actionButtons {
	position: absolute;
	top: 0;
	left: 0;
	z-index: 3;
	width: 100%;
	padding: 10px;
	box-sizing: border-box;
	/* border: 1px solid red; */
}

  /* .back-button {
	position: absolute;
	z-index: 2100;
	background: transparent;
	top: 50px;
	border: none;
  }
  
  .back-button img {
	width: 50px;
  } */

/* Add to src/products/ProductDetail.css */
/* .loading-screen {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
	color: #fff;
	font-size: 20px;
	background-color: #000;
} */
  