.no-bullets {
	list-style-type: none;
	padding-left: 0;
}

.no-bullets li {
	margin-bottom: 10px; 
}

body {
	/* background-color: #000;  */
	/* color: #fff;  */
}

h1 {
	margin-bottom: 10px;
}

.modal {
	display: flex;
	justify-content: center;
	align-items: center;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	z-index: 1000;
}

.modal-content {
	background-color: #fff;
	padding: 20px;
	border-radius: 5px;
	position: relative;
	width: 300px;
	text-align: center;
}

.close-button {
	margin-top: 10px;
	padding: 10px;
	background-color: #007bff;
	color: white;
	border: none;
	border-radius: 5px;
	cursor: pointer;
}

.close-button:hover {
	background-color: #0056b3;
}

.grid-container {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	gap: 10px;
	margin-bottom: 10px;
}

.grid-button {
	padding: 10px;
	background-color: #007bff;
	color: white;
	border: none;
	border-radius: 5px;
	cursor: pointer;
}

.grid-button:hover {
	background-color: #0056b3;
}