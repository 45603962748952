.fit-gauge {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    width: 100%;
	/* border: 1px solid yellow; */
}

.fit-gauge-label {
    font-size: 12px;
    color: #fff;
    margin-right: 5px;
    /* 	flex: 1; */
    text-align: right;
	/* border: 1px solid purple; */
	width: 35%;
}

.fit-gauge-bar-container {
    display: flex;
    align-items: center;
    /* width: 40%; */
	/* border: 1px solid blue; */
}

.fit-gauge-bar {
    /* flex-grow: 1; */
    height: 5px;
    /* background-color: #ccc; */
	background-color: #686868;
    border-radius: 4px;
    overflow: hidden;
    position: relative;
	/* border: 1px solid red; */
	width: 30%;
}

.fit-gauge-fill {
    height: 100%;
    background-color: #007bff;
	background-color: white;
    transition: width 0.5s ease;
}

.fit-gauge-phrase {
    font-size: 12px;
    color: #fff;
    margin-left: 5px;
    /* flex: 1; */
    text-align: left;
	/* border: 1px solid pink; */
	width: 35%;
}
