.productDetail .product-info {
	position: absolute;
	bottom: 0%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100%;
	padding: 100px 0 0px 0;
	background: linear-gradient(to top, rgba(0, 0, 0, 0.90), rgba(0, 0, 0, 0));
	color: white;
}

.product-info .logo {
	width: 50%;
	margin-bottom: 10px;
}

.product-info .attributes {
	font-size: 12px;
	margin: 5px;
}

.product-info .summary {
	margin: 0;
	width: 75%;
	font-size: 14px;
	color: #fff;
	text-align: center;
}

.product-info .fits {
	width: 100%;
	padding: 10px 20px;
}