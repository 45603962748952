.app {
	/* background: green; */
}

.app .menuBar {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 1;
}

/* .buttons {
	display: flex;
	flex-direction: column;
	width: 200px;
} */

/* button {
	font-size: 16px;
	padding: 10px;
	margin: 10px 0;
} */

/* .product-content-placeholder {
	background: orange;
	height: 2000px;
} */