.videoPlayer {
    position: relative;
    width: 100%;
    padding-top: 177.78%; /* 9:16 aspect ratio (9 / 16 * 100) */
    background: black;
    overflow: hidden;
}

.react-player {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

/* .button-group {
    position: absolute;
    top: 10px;
    right: 10px;
    display: flex;
    gap: 10px;
    z-index: 1;
} */

/* .replay-button, .mute-button {
    background: rgba(0, 0, 0, 0.5);
    border: none;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.replay-button img, .mute-button img {
    width: 25px;
    height: 25px;
} */
