/* src/tabs/search/SearchTab.css */

.search-tab .no-bullets {
	list-style-type: none;
	padding-left: 0;
}

.search-tab .no-bullets li {
	margin-bottom: 10px; 
}

.search-tab h1 {
	margin-bottom: 10px;
}

.search-tab .modal {
	display: flex;
	justify-content: center;
	align-items: center;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	z-index: 1000;
}

.search-tab .modal-content {
	background-color: #fff;
	padding: 20px;
	border-radius: 5px;
	position: relative;
	width: 300px;
	text-align: center;
}

.search-tab .close-button {
	margin-top: 10px;
	padding: 10px;
	background-color: #007bff;
	color: white;
	border: none;
	border-radius: 5px;
	cursor: pointer;
}

.search-tab .close-button:hover {
	background-color: #0056b3;
}

.search-tab .grid-container {
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
	gap: 10px;
	margin-bottom: 10px;
	padding: 10px;
}

.search-tab .product-tile-wrapper {
	display: flex;
	justify-content: center;
}

.search-tab .search-bar-container {
	position: relative;
	display: flex;
	align-items: center;
	padding-left: 10px;
}

.search-tab .search-bar {
	width: 400px;
	padding: 10px;
	padding-right: 30px; /* Add padding to make space for the icon */
	margin-bottom: 10px;
	border: 1px solid #ccc;
	border-radius: 5px;
	box-sizing: border-box;
	/* background-color: #333; */
	/* color: #fff; */
	border: 1px solid gray;
	font-size: 16px;
}

.search-tab .search-bar:focus {
	outline: none;
	border-color: #555; /* Change border color on focus */
}
/*   
.clear-icon {
position: absolute;
right: 10px;
width: 20px;
height: 20px;
cursor: pointer;
} */

.search-tab .clear-icon {
	/* position: absolute; */
	width: 20px;
	height: 20px;
	cursor: pointer;
	padding-left: 10px;
	padding-bottom: 10px;
	/* border: 1px solid red; */
}